<template>
  <common-table
    :fields="fields"
    :busy="viewstate.loading"
    :sortBy="sortBy"
    :sortDesc="true"
    :dataProvider="holeProvisionslog"
    :usePagination="usePagination"
    :useClientSorting="true"
    show-empty
  >
    <template v-slot:empty>
      <h4 class="py-3 text-center">
        Es sind derzeit keine Provision gepflegt.
      </h4>
    </template>
    <template v-slot:table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </template>
    <template #cell(centProvisionAlt)="row">
      {{ row.item.centProvisionAlt | centsToEuros }}
    </template>
    <template #cell(centProvisionNeu)="row">
      {{ row.item.centProvisionNeu | centsToEuros }}
    </template>
    <template #cell(dateTime)="row">
      {{ row.item.dateTime | formatDateTime }}
    </template>
  </common-table>
</template>

<script>
import CommonTableMixin from "@pso/vue-shared/mixins/componentRelation/commonTable"
import provision from "../../api/kpi-energie-api/provision"
import { mapActions, mapState } from "vuex"
import {
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
} from "../../store/modules/viewstate"

export default {
  name: "Provisionslogtabelle",
  mixins: [CommonTableMixin],
  computed: {
    ...mapState({
      viewstate: (state) => state.viewstate.viewstate,
    }),
  },
  data() {
    return {
      fields: [
        { key: "geschäftsfall", label: "Geschäftsfall", sortable: true },
        { key: "tarif", label: "Tarif", sortable: true },
        { key: "centProvisionNeu", label: "Kondition aktuelle Wert", sortable: true },
        { key: "centProvisionAlt", label: "Kondition alter Wert", sortable: true },
        { key: "dateTime", label: "Änderung am", sortable: true },
      ],
      sortBy: "dateTime",
      usePagination: false
    }
  },
  methods: {
    ...mapActions({
      setState: "viewstate/setState",
    }),
    holeProvisionslog(filter) {
      this.setState({
        loading: true,
        messageType: this.viewstate.messageType,
        message: this.viewstate.message,
      })
      return provision
        .holeProvisionslog(filter)
        .then((response) => {
          // Todo wird eine aus success message benötigt?
          this.setState({
            loading: this.viewstate.loading,
            messageType: MESSAGE_TYPE_SUCCESS,
            message: "",
          })
          return response
        })
        .catch(() => {
          // Todo aus error die message erstellen
          this.setState({
            loading: this.viewstate.loading,
            messageType: MESSAGE_TYPE_ERROR,
            message: "",
          })
        })
        .finally(() => {
          this.setState({
            loading: false,
            messageType: this.viewstate.messageType,
            message: this.viewstate.message,
          })
        })
    },
  },
}
</script>

<style scoped>
</style>
