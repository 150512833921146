<template>
    <div>
        <common-table :fields="fields"
                      :busy="viewstate.loading"
                      :sortBy="sortBy"
                      :dataProvider="holeMitarbeiterStatistik"
                      :usePagination="false"
                      :useClientSorting="true"
                      :other-filters-size="8"
                      show-empty>
            <template v-slot:beforeSearchFilter="filterMutations">
                <mitarbeiterstatistik-monat-filter style="float: left; width: 20%" :filterMutations="filterMutations" />
            </template>
            <template v-slot:empty>
                <h4 class="py-3 text-center">
                    Keine Daten gefunden.
                </h4>
            </template>
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                </div>
            </template>
            <template #cell(kwAnteil)="row">
                {{ row.item.kwAnteil | formatPercentage }}
            </template>
            <template #cell(stornoQuote)="row">
                {{ row.item.stornoQuote | formatPercentage }}
            </template>
            <template #cell(umsatzOffeneAufträge)="row">
                {{ row.item.umsatzOffeneAufträge | formatEuro }}
            </template>
            <template #cell(umsatzGesamt)="row">
                {{ row.item.umsatzGesamt | formatEuro }}
            </template>
        </common-table>
      <p>*Bei den offenen Aufträgen zeigen wir nur Aufträge aus den letzten 31 Tagen an. Sobald Aufträge storniert wurden, sind sie nicht mehr Teil der Berechnung</p>
      <p v-if="isVm() && !hasTeamMitglieder">Derzeit sind dir keine Vertriebsmitarbeiter zugeteilt</p>
    </div>
</template>

<script>
import CommonTableMixin from '@pso/vue-shared/mixins/componentRelation/commonTable'
import MitarbeiterstatistikMonatFilter from '../../components/common/MitarbeiterstatistikMonatFilter'
import umsatz from '../../api/kpi-energie-api/umsatz'
import { mapActions, mapState, mapGetters } from "vuex"
import {
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
} from "../../store/modules/viewstate"

export default {
  name: 'MitarbeiterStatistikTabelle',
  mixins: [CommonTableMixin],
  components: { MitarbeiterstatistikMonatFilter },
  computed: {
    ...mapState({
      viewstate: (state) => state.viewstate.viewstate,
    }),
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'VM', sortable: true },
        { key: 'anzahlAufträge', label: 'Anzahl Aufträge', sortable: true },
        { key: 'kwAnteil', label: 'Der KW Anteil', sortable: true },
        { key: 'stornoQuote', label: 'Die Stornoquote', sortable: true },
        { key: 'umsatzOffeneAufträge', label: '€ offene Aufträge*', sortable: true },
        { key: 'umsatzGesamt', label: 'Umsatz gesamt', sortable: true },
      ],
      sortBy: 'name',
      monate: null,
      selectedMonat: null,
      hasTeamMitglieder: true,
    }
  },
  methods: {
    ...mapActions({
      setState: "viewstate/setState",
    }),
    ...mapGetters({
      getUser: 'user/getUser'
    }),
    holeMitarbeiterStatistik(filter) {
      this.setState({
        loading: true,
        messageType: this.viewstate.messageType,
        message: this.viewstate.message,
      })
      return umsatz.ladeMitarbeiterStatistik(filter)
        .then((response) => {
          this.setState({
            loading: this.viewstate.loading,
            messageType: MESSAGE_TYPE_SUCCESS,
            message: "",
          })
          if(this.isVm() && response.data.length === 1) {
            this.hasTeamMitglieder = false;
          }
          return response
        })
        .catch(() => {
          this.setState({
            loading: this.viewstate.loading,
            messageType: MESSAGE_TYPE_ERROR,
            message: "",
          })
        })
        .finally(() => {
          this.setState({
            loading: false,
            messageType: this.viewstate.messageType,
            message: this.viewstate.message,
          })
        })
    },
    isVm() {
      const user = this.getUser();
      return user !== null && user !== undefined && user.type === 'VM';
    }
  }
}
</script>

<style scoped>
</style>