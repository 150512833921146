import Vue from 'vue'
import App from './App'
import './assets/custom.scss'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import store from './store'
import IdleVue from 'idle-vue'

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 28 * 60000, // * 60000 ms
  startAtIdle: false
});

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

Vue.filter('formatDateTime', function (value) {
  if (value) {
    var date = new Date(value);
    return date.getFullYear() + "."
      + (date.getMonth() > 9 ? date.getMonth() : ("0" + (date.getMonth() + 1))) + "."
      + (date.getDate() > 9 ? date.getDate() : ("0" + date.getDate())) + " - "
      + (date.getHours() > 9 ? date.getHours() : ("0" + date.getHours())) + ":"
      + (date.getMinutes() > 9 ? date.getMinutes() : ("0" + date.getMinutes()))
  }
})

Vue.filter('formatDate', function (value) {
  if (value) {
    let date = new Date(value);
    return (date.getDate() > 9 ? date.getDate() : ("0" + date.getDate())) + "."
      + (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : ("0" + (date.getMonth() + 1))) + "."
      + date.getFullYear()
  }
})

Vue.filter('centsToEuros', function (value) {
  if (value) {
    return new Intl.NumberFormat('de-DE').format(value/100) + ' \u20ac'
  }
  return '0 \u20ac'
})

Vue.filter('formatEuro', function (value) {
  if (value) {
    return new Intl.NumberFormat('de-DE').format(value) + ' \u20ac'
  }
  return '0 \u20ac'
})

Vue.filter('formatPercentage', function (value) {
  if (value) {
    return new Intl.NumberFormat('de-DE').format(value) + '%';
  }
  return '0%'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
