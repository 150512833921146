<template>
  <div class="mt-3 mb-3 motivationstext">
    <h2 class="mutmach-text">{{ calcUmsatzText() }}</h2>
  </div>
</template>


<script>
export default {
  name: 'Motivationstext',
  props: {
    aktuellerWert: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      default: 600
    }
  },
  data() {
    return {
      umsatzText: {
        99: [
          'Leg mal endlich los',
          'Fang endlich an'
        ],
        249: [
          'Da geht noch was, gib Gas!',
          'Streng dich an'
        ],
        399: [
          'Mach weiter und geh hoch hinaus!',
          'Du bist auf einem guten Weg!'
        ],
        599: [
          'Super! - Kämpfe weiter und werde heute die Nummer 1!',
          // 'Du bist fast am Ziel - Jetzt nicht aufgeben'
        ],
        600: [
          'Unfassbarer Tag! Du kannst stolz auf deine geile Leistung sein',
          'Das hast du super gemacht'
        ]
      }
    }
  },
  methods: {
    calcUmsatzText() {
      let array = []
      if (this.aktuellerWert === undefined) {
        return ''
      }
      if (this.aktuellerWert < 100) {
        array = this.umsatzText[99]
      } else if (this.aktuellerWert <= 249) {
        array = this.umsatzText[249]
      } else if (this.aktuellerWert <= 399) {
        array = this.umsatzText[399]
      } else if (this.aktuellerWert <= 599) {
        array = this.umsatzText[599]
      } else {
        array = this.umsatzText[600]
      }

      return array[Math.floor(Math.random() * array.length)]
    }
  }
}
</script>

<style scoped>
.mutmach-text {
  color: #067FD0;
  font-weight: bolder;
  font-style: italic;
}
</style>
