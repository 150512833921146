<template>
    <b-row>
        <b-col>
            <b-row class="py-3">
                <b-col>
                    <h1>Provisionslog</h1>
                </b-col>
            </b-row>
            <b-row class="py-3">
                <b-col>
                    <provisionslogtabelle/>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

</template>

<script>
  import Provisionslogtabelle from '../common/Provisionslogtabelle'

  export default {
    name: 'PageProvisionslog',
    components: {Provisionslogtabelle},
    metaInfo() {
      return {
        title: 'Provisionsverwaltung'
      }
    }
  }
</script>

<style scoped>

</style>
