<template>
  <b-row>
    <b-col>
      <b-row class="py-3">
        <b-col>
          <h1>Mitarbeiter Auftragsliste</h1>
        </b-col>
      </b-row>
      <b-row class="py-3">
        <b-col>
          <mitarbeiter-auftragstabelle ref="auftragstabelle" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import MitarbeiterAuftragstabelle from "../common/MitarbeiterAuftragstabelle";

export default {
  name: 'PageMitarbeiterAuftragsliste',
  components: {MitarbeiterAuftragstabelle},
  metaInfo() {
    return {
      title: 'Mitarbeiter Auftragsliste'
    }
  },
}
</script>

<style scoped>
</style>