import HTTP from '../http-common'

export default {
    holeTeamMitarbeiter(filter) {
        return HTTP.post('/kpi/team/getTeamMitarbeiter', filter);
    },
    holeTeamMitglieder(teamleiterKey) {
        return HTTP.get('/kpi/team/getTeamMitglieder?teamleiterKey=' + teamleiterKey);
    },
    setzeTeammitglieder(data) {
        return HTTP.post('/kpi/team/setTeam', data);
    },
    holeMitarbeiterActivity(data, dateTime) {
        return HTTP.post('/kpi/team/getMitarbeiterAktivitaet?dateTime=' + dateTime, data);
    },
    holeMitarbeiterDetailedActivity(appUserId, dateTime) {
        return HTTP.post('/kpi/team/getMitarbeiterDetailedAktivitaet?appUserId=' + appUserId + '&dateTime=' + dateTime);
    },
    holeLetzteMitarbeiterActivity(appUserId) {
        return HTTP.post('/kpi/team/getLastMitarbeiterAktivitaet?appUserId=' + appUserId);
    }
}