import HTTP from '../http-common'

export default {
  ladeDashboard () {
    return HTTP.get('/kpi/umsatz/dashboard');
  },    
  ladeMitarbeiterStatistik(filter) {
    return HTTP.post('/kpi/umsatz/mitarbeiterStatistik', filter )
  },
  holeMonate() {
    return HTTP.get(`/kpi/umsatz/mitarbeiterStatistikMonate`)
  },
}
