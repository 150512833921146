import { render, staticRenderFns } from "./PageSperrgebieteExport.vue?vue&type=template&id=70e3e622&scoped=true&"
import script from "./PageSperrgebieteExport.vue?vue&type=script&lang=js&"
export * from "./PageSperrgebieteExport.vue?vue&type=script&lang=js&"
import style0 from "./PageSperrgebieteExport.vue?vue&type=style&index=0&id=70e3e622&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e3e622",
  null
  
)

export default component.exports