<template>
  <div>
    <h1>Login</h1>
        <p>Um sich einzuloggen, bitte folgenden Link aufrufen:</p>
        <p><a :href="vueAppTokenApiUrl" title="Login">FairtriebsChampions</a></p>
  </div>
</template>

<script>
import store from '../../store'

export default {
  name: 'PageLogout',
  data() {
    return {
      vueAppTokenApiUrl: process.env.VUE_APP_TOKEN_API_URL
    }
  },
  created() {
    store.dispatch('user/setToken', null)
    store.dispatch('user/setUser', null)
  }
}
</script>