<template>
    <b-row class="umsatzanzeige">
        <b-col cols="10" class="umsatzanzeige-col-balken">
            <div>
                <div class="umsatzanzeige-col-balken-filled"
                     :style="{'width': `${verhaeltnisAktuellerWertVonMaximalerWert}%`, 'background-color': balkenFarbe }">

                </div>
                <div class="umsatzanzeige-col-balken-empty"
                     :style="{'width': `${100 - verhaeltnisAktuellerWertVonMaximalerWert}%`}">
                </div>
            </div>
        </b-col>
        <b-col cols="2" class="umsatzanzeige-col-text">
            <span class="umsatzanzeige-col-text-value v-align-top" :style="{ 'color': balkenFarbe }">{{aktuellerWert}}€</span>
        </b-col>
    </b-row>
</template>

<script>
  export default {
    name: 'Umsatzanzeige',
    props: {
      maximalerWert: {
        type: Number,
        required: true
      },
      aktuellerWert: {
        type: Number,
        required: true
      },
      balkenFarbe: {
        type: String,
        required: false,
        default: () => '#85CAE7'
      }
    },
    computed: {
      verhaeltnisAktuellerWertVonMaximalerWert() {
        return Math.min(100, (this.aktuellerWert / this.maximalerWert) * 100)
      }
    }
  }
</script>

<style scoped>

</style>
