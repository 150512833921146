import * as types from '../mutation-types'
import provision from '../../api/kpi-energie-api/provision'
import Vue from 'vue'
import store from '../index'
import {MESSAGE_TYPE_INFO} from './viewstate'

// initial state
const state = {
  provisionChanges: {},
  provision: [],
  apiResponse: null,
  filters: [],
  geschaeftsfaelle: [],
  selectedKategorie: 'Keine Auswahl',
  selectedGeschaeftsfall: 'Keine Auswahl',
}

// getters
const getters = {
  changedProvisionById: state => id => state.provisionChanges[id],
  valuesOther: state => state.map.map(s => {return {key: s.key, value: s.value.centProvision}})
}

// actions
const actions = {
  setUpdates({commit}, entry) {
    commit(types.CHANGE_PROVISION_CHANGES, entry)
  },
  setFilters({commit}, entry) {
    commit(types.CHANGE_PROVISION_FILTERS, [...entry])
  },
  setSelectedKategorie({commit}, entry) {
    commit(types.CHANGE_PROVISION_SELECTED_KATEGORIE, entry)
  },
  setSelectedGeschaeftsfall({commit}, entry) {
    commit(types.CHANGE_PROVISION_SELECTED_GESCHAEFTSFALL, entry)
  },
  resetChanges({ commit, state }) {
    commit(types.CLEAR_PROVISION_CHANGES, state)
  },
  saveChanges({ commit, state }, caller) {
    const data = Object.keys(state.provisionChanges).map(
      x => { return {
        Key: x,
        ProvisionId: parseInt(x.split('#')[0]),
        CentProvision: state.provisionChanges[x],
        PartnerId: parseInt(x.split('#')[1])
        }
      })
      provision.speichereProvisionen(data)
      .then(x => {
        if(x.status === 200)
        {
          caller.$bvToast.toast('Änderungen erfolgreich gespeichert', {
            variant: 'success',
            autoHideDelay: 3000,
            appendToast: false
          })
          for (let index = 0; index < data.length; index++) {
            const changes = data[index];
            commit(types.CHANGE_PROVISION_CENTPROVISION, {id: changes.Key, centProvision: changes.CentProvision})
          }
          commit(types.CLEAR_PROVISION_CHANGES)
        }
      })
      .catch(error => {
        caller.$bvToast.toast(`Fehler beim Speichern der Änderungen: ${error.message}`, {
          variant: 'danger',
          autoHideDelay: 3000,
          appendToast: false
        })
      })
  },
  setProvisionlist({commit, state}, params) {
    store.dispatch('viewstate/setState', {
      loading: true,
      messageType: MESSAGE_TYPE_INFO,
      message: 'Provisionen werden geladen',
    })

    if(state.apiResponse !== null) {
      return state.apiResponse
    }

    return provision
      .holeProvisionen(params.filters)
      .then((response) => {
        let data = response.data.reduce((a, b) => {
          return {
            ...a,
            [b.key]: b
          }
        }, {})

        commit(types.CHANGE_PROVISION, data)
        if (state.geschaeftsfaelle.length == 0) {
          let unique = []
          for (const [, value] of Object.entries(data)) {
            if(!unique.includes(value.geschäftsfall)) {
              unique.push(value.geschäftsfall)
            }
          }
          commit(types.CHANGE_PROVISION_GESCHAEFTSFAELLE, unique)
        }
        return response
      })
      .catch((error) => {
        params.caller.$bvToast.toast(`Fehler beim Abrufen der Provisionen: ${error.message}`, {
          variant: 'danger',
          autoHideDelay: 3000,
          appendToast: false
        })
      })
      .finally(() => {
      })
  }
}

// mutations
const mutations = {
  [types.CHANGE_PROVISION_CHANGES](state, data) {
    let tmp = {...state.provisionChanges}
    let neuerWertIstOriginalWert = data.newValue === state.provision[data.id].centProvision
    if (neuerWertIstOriginalWert && tmp[data.id]) {
      tmp[data.id] = null
      delete tmp[data.id]
    } else if (!neuerWertIstOriginalWert) {
      tmp[data.id] = data.newValue
    }
    state.provisionChanges = tmp
  },
  [types.CHANGE_PROVISION](state, data) {
    state.provision = data
  },
  [types.CHANGE_PROVISION_SELECTED_GESCHAEFTSFALL](state, data) {
    state.selectedGeschaeftsfall = data
  },
  [types.CHANGE_PROVISION_SELECTED_KATEGORIE](state, data) {
    state.selectedKategorie = data
  },
  [types.CHANGE_PROVISION_GESCHAEFTSFAELLE](state, data) {
    state.geschaeftsfaelle = data
  },
  [types.CHANGE_PROVISION_FILTERS](state, data) {
    state.filters = data
  },
  [types.CHANGE_PROVISION_CENTPROVISION](state, data) {
    let newProvision = {...state.provision}
    newProvision[data.id].centProvision = data.centProvision
    state.provision = newProvision
  },
  [types.CLEAR_PROVISION_CHANGES](state) {
    state.provisionChanges = {}
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
