import * as types from '../mutation-types'
import team from "../../api/kpi-energie-api/team";
import store from '../index'
import {MESSAGE_TYPE_INFO} from "@/store/modules/viewstate";

const state = {
    teamMitarbeiter: [],
    teamMitglieder: [],
    teamMitgliederList: [],
    changedTeammitglieder: {},
    filters: [],
}

const getters = {
    changedTeammitgliedByKey: state => key => state.changedTeammitglieder[key],
}

const actions = {
    setMitarbeiterList({commit, state}, params) {
        store.dispatch('viewstate/setState', {
            loading: true,
            messageType: MESSAGE_TYPE_INFO,
            message: 'Mitarbeiter werden geladen'
        })

        return team.holeTeamMitarbeiter(params.filters)
            .then((response) => {
                let data = response.data.reduce((a,b) => {
                    return {
                        ...a,
                        [b.key]: b
                    }
                }, {});
                commit(types.CHANGE_TEAM_MITARBEITER, data);
                return response;
            })
            .catch((error) => {
                params.caller.$bvToast.toast(`Fehler beim Laden der Mitarbeiter: ${error.message}`, {
                    variant: 'danger',
                    autoHideDelay: 3000,
                    appendToast: false
                })
            })
            .finally(() => {});
    },
    setNewSelectedTeamleiter({commit}, data) {
        commit(types.CHANGE_SELECTED_TEAMLEITER, data);
    },
    setTeamMitgliederList({commit, state}, params) {
        store.dispatch('viewstate/setState', {
            loading: true,
            messageType: MESSAGE_TYPE_INFO,
            message: 'Team Mitglieder werden geladen'
        });

        return team.holeTeamMitglieder(params.leiterId)
            .then((response) => {
                let data = response.data.reduce((a,b) => {
                    return {
                        ...a,
                        [b.key]: b
                    }
                }, {});
                commit(types.CHANGE_TEAM_MITGLIEDER, data);
                commit(types.CHANGE_TEAM_MITGLIEDER_LIST, response.data);
                return response;
            })
            .catch((error) => {
                params.caller.$bvToast.toast(`Fehler beim Laden der Team Mitglieder: ${error.message}`, {
                    variant: 'danger',
                    autoHideDelay: 3000,
                    appendToast: false
                })
            })
            .finally(() => {});
    },
    clearTeamMitgliederList({commit, state}) {
        commit(types.CHANGE_TEAM_MITGLIEDER_LIST, []);
    },
    setUpdated({commit}, entry) {
        commit(types.CHANGE_TEAM_MITGLIED_STATUS, entry);
    },
    clearTeammitgliederChanges({commit, state}) {
        commit(types.CHANGE_TEAM_MITGLIEDER_CHANGES, {});
    },
    saveTeammitglieder({commit, state}, params) {
        const data = Object.keys(state.changedTeammitglieder).map(
            c => { return {
                key: c,
                teamleiterKey: params.teamleiterKey,
                isInTeam: state.changedTeammitglieder[c].selected
            }
            });
            team.setzeTeammitglieder(data)
                .then(c => {
                    if(c.status == 200) {
                        params.caller.$bvToast.toast('Änderungen erfolgreich gespeichert', {
                            variant: 'success',
                            autoHideDelay: 3000,
                            appendToast: false
                        });
                    }
                    for(let index = 0; index < data.length; index++) {
                        const changes = data[index];
                        commit(types.CHANGE_TEAM_MITGLIED_STATUS, {key: changes.key, selected: changes.isInTeam});
                    }
                    commit(types.CHANGE_TEAM_MITGLIEDER_CHANGES, {});
                })
                .catch(error => {
                    params.caller.$bvToast.toast(`Fehler beim Speichern der Änderungen: ${error.message}`, {
                        variant: 'danger',
                        autoHideDelay: 3000,
                        appendToast: false
                    })
                })
    }
}

const mutations = {
    [types.CHANGE_TEAM_MITARBEITER](state, data) {
        state.teamMitarbeiter = data;
    },
    [types.CHANGE_TEAM_MITGLIEDER](state, data) {
        state.teamMitglieder = data;
    },
    [types.CHANGE_TEAM_MITGLIEDER_LIST](state, data) {
      state.teamMitgliederList = data;
    },
    [types.CHANGE_TEAM_MITGLIEDER_CHANGES](state, data) {
        state.changedTeammitglieder = data;
    },
    [types.CHANGE_SELECTED_TEAMLEITER](state, data) {
        let newSelectedTeamleiter = {...state.teamMitarbeiter}
        for(const [key, value] of Object.entries(newSelectedTeamleiter))
        {
            if(key == data.key) {
                value.selected = !value.selected;
            }
            else {
                value.selected = false;
            }
        }
        this.teamMitarbeiter = newSelectedTeamleiter;
    },
    [types.CHANGE_TEAM_MITGLIED_STATUS](state, data) {
        let tmp = {...state.changedTeammitglieder}
        let neuerWertIstOriginal = data.selected === state.teamMitglieder[data.key].selected;
        if(neuerWertIstOriginal && tmp[data.key]) {
            tmp[data.key] = null;
            delete tmp[data.key];
        }
        else if(!neuerWertIstOriginal) {
            tmp[data.key] = {selected: data.selected};
        }
        state.changedTeammitglieder = tmp;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

