<template>
  <div class="auftragsmonat-filter">
    <b-form-group label="Monat" :label-cols="11">
      <b-form-select v-model="selectedMonat"
                     :options="monate"
                     :plain="true"
                     @change="updateFilter" />
    </b-form-group>
  </div>
</template>

<script>
import Vue from 'vue'
import { FormGroupPlugin, FormSelectPlugin } from 'bootstrap-vue'
import auftrag from '../../api/kpi-energie-api/auftrag'

Vue.use(FormGroupPlugin)
Vue.use(FormSelectPlugin)

export default {
  name: 'MitarbeiterAuftragsmonatFilter',
  props: {
    filterMutations: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      monate: null,
      selectedMonat: null
    }
  },
  methods: {
    updateFilter (val) {
      this.filterMutations.remove('Monat')
      if (val !== 'Keine Auswahl') {
        this.filterMutations.add({ Name: 'Monat', Value: val })
      }
      this.filterMutations.update()
    }
  },
  created() {
    auftrag.holeMitarbeiterMonate()
        .then(response => {
          this.monate = response.data
          this.selectedMonat = this.monate[0]
          this.updateFilter(this.selectedMonat)
        })
  }
}
</script>

<style scoped>
</style>
