<template>
    <div class="geschaeftsfall-filter">
        <b-form-group label="Geschäftsfall" :label-cols="11">
            <b-form-select v-model="selectedGeschaeftsfall"
                    :options="geschaeftsfaelle"
                    :plain="true"
                    @change="updateFilter"/>
        </b-form-group>
    </div>
</template>

<script>
  import Vue from 'vue'
  import { FormGroupPlugin, FormSelectPlugin } from 'bootstrap-vue'
  import auftrag from '../../api/kpi-energie-api/auftrag'

  Vue.use(FormGroupPlugin)
  Vue.use(FormSelectPlugin)

  export default {
    name: 'GeschaeftsfallFilter',
    props: {
      filterMutations: {
        type: Object,
        required: true
      }
    },    
    data () {
      return {
        geschaeftsfaelle: null,
        selectedGeschaeftsfall: null
      }
    },
    methods: {
      updateFilter (val) {
        this.filterMutations.remove('Geschäftsfall')
        if (val !== 'Keine Auswahl') {
          this.filterMutations.add({ Name: 'Geschäftsfall', Value: val })
        }
        this.filterMutations.update()
      }
    },    
    created() {
      auftrag.holeGeschaeftsfaelle()
        .then(response => {
          this.geschaeftsfaelle = response.data
          this.geschaeftsfaelle.unshift('Keine Auswahl')
          this.selectedGeschaeftsfall = this.geschaeftsfaelle[0]
        })
    }
  }
</script>

<style scoped>

</style>
