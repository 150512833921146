<template>
  <div class="kategorie-filter">
    <b-form-group label="Kategorie" :label-cols="11">
      <b-form-select
          :value="selectedKategorie"
          :options="options"
          :plain="true"
          @change="updateFilter"/>
    </b-form-group>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { FormGroupPlugin, FormSelectPlugin } from 'bootstrap-vue'

Vue.use(FormGroupPlugin)
Vue.use(FormSelectPlugin)

export default {
  name: 'KategorieFilter',
  props: {
    filterMutations: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      selectedKategorie: state => state.provision.selectedKategorie
    }),
    options () {
      let tmp = [...this.kategorien]
      tmp.unshift('Keine Auswahl')
      return tmp
    }
  },
  data () {
    return {
      kategorien: ['DoublePlay', 'TriplePlay']
    }
  },
  methods: {
    ...mapActions({
      setSelectedKategorie: 'provision/setSelectedKategorie'
    }),
    updateFilter (val) {
      this.filterMutations.remove('Kategorie')
      if (val !== 'Keine Auswahl') {
        this.filterMutations.add({ Name: 'Kategorie', Value: val })
      }
      this.filterMutations.update()
      this.setSelectedKategorie(val)
    }
  }
}
</script>

<style scoped>

</style>
