<template>
    <b-row>
        <b-col>
            <b-row class="py-3">
                <b-col>
                    <h1>Provisionsübersicht</h1>
                </b-col>
            </b-row>
            <b-row class="py-3">
                <b-col>
                    <provisionstabelle ref="provisionstabelle" />
                </b-col>
            </b-row>
        </b-col>
    </b-row>

</template>

<script>
  import Provisionstabelle from '../common/Provisionstabelle'

  export default {
    name: 'PageProvisionsverwaltung',
    components: {Provisionstabelle},
    metaInfo() {
      return {
        title: 'Provisionsverwaltung'
      }
    },
  beforeRouteLeave (to, from, next) {
    if (this.$refs.provisionstabelle.changedItems.length > 0) {
      if (confirm('Sie haben ungespeicherte Änderungen. Möchten Sie diese Seite verlassen?') === true) {
        next()
      }
      return
    }
    next()
  }
  }
</script>

<style scoped>

</style>
