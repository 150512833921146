<template>
  <div>
    <b-row>
      <b-col>
        <b-row class="py-3">
          <b-col>
            <h1>Karriereleiter</h1>
          </b-col>
        </b-row>
        <b-row class="py-3">
          <b-col>
            <karriereleiter-tabelle ref="karriereleiter-tabelle"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-row class="py-3">
          <b-col>
            <h1>Teamverwaltung</h1>
          </b-col>
        </b-row>
        <team-tabelle ref="team-tabelle"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KarriereleiterTabelle from "@/components/common/KarriereleiterTabelle";
import TeamTabelle from "../common/TeamTabelle";

export default {
  name: "PageKarriereleiter",
  components: {TeamTabelle, KarriereleiterTabelle},
  metaInfo() {
    return {
      title: 'Karriereleiter'
    }
  },
}
</script>

<style scoped>

</style>