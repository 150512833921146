<template>
  <div>
    <b-row>
      <b-col>
        <h4 class="mb-3">{{ headline }}</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <vue-ellipse-progress
          :progress="progress"
          :angle="-90"
          :color="circleColor"
          emptyColor="#ededed"
          :size="150"
          :thickness="10"
          :emptyThickness="8"
          :legend="true"
          :legendFormatter="({currentValue}) => new Intl.NumberFormat('de-DE').format(currentValue) +'%'"
          legendClass="legend-custom-style"
          :noData="false"
          :loading="false"
          :fontColor="circleColor"
          :half="false"
          line-mode="in 5"
          fontSize="2rem"
          animation="bounce 1700 200"
        >
          <span slot="legend-value"></span>
          <p slot="legend-caption">{{legendCaption}}</p>
        </vue-ellipse-progress>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Vue from 'vue'
  import VueEllipseProgress from 'vue-ellipse-progress'
  Vue.use(VueEllipseProgress)

  export default {
    name: "CircleAnzeige",
    props: {
      progress: {
        type: Number,
        required: false,
        default: 0
      },
      headline: {
        type: String,
        required: true
      },
      legendCaption: {
        type: String,
        required: false
      },
      circleColor: {
        type: String,
        required: false,
        default: "#0e0062"
      }
    }
  }
</script>

<style>
.legend-custom-style{
  font-weight: 700;
}
.ep-legend--container p {
  font-size:.85rem;
}
</style>
