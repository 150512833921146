// Setup des Stores
// vgl. https://vuex.vuejs.org/en/structure.html
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import viewstate from './modules/viewstate'
import user from './modules/user'
import provision from './modules/provision'
import karriere from './modules/karriere'
import team from './modules/team'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const vuexSession = new VuexPersistence({
  strictMode: debug,
  storage: window.sessionStorage
})

const store = new Vuex.Store({
  // actions,
  // getters,
  modules: {
    viewstate,
    user,
    provision,
    karriere,
    team
  },
  mutations: {
    RESTORE_MUTATION: vuexSession.RESTORE_MUTATION // this mutation **MUST** be named "RESTORE_MUTATION"
  },
  strict: debug,
  plugins: [vuexSession.plugin]
})

export default store
