import axios from 'axios'

const TOKEN_HTTP = axios.create({
  baseURL: process.env.VUE_APP_TOKEN_API_URL,
  timeout: 300 * 1000, // timeout in milliseconds
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
})

export default TOKEN_HTTP
