<template>
    <div>
      <b-modal id="info-modal" ok-only>
        <h3>Dein Bruttoumsatz:</h3>
        <p>
          Wir betrachten hier alle Aufträge, die im Status "Offen (Quality Call positiv)" und "ok / Übermittelt an die Telekom" sind.
          Wenn ein Auftrag bei der Übermittlung (z.B. Bonität) oder danach noch storniert wird, wird der Auftragswert wieder abgezogen.
        </p>
        <h3>Deine Stornoquote</h3>
        <p>
          Gezählt werden alle Aufträge, die vor Übermittlung an die Deutsche Telekom storniert wurden. Provisionsstornos werden nicht betrachtet.
          Es werden nur Stornos von erfassten Aufträgen gezählt. Wir betrachten immer den aktuellen Kalendermonat bzw. den Vormonat.
        </p>
        <h3>Dein KW Anteil</h3>
        <p>
          Als Komplettwechsel gezählt werden die Aufträge mit den Geschäftsfällen "Komplettwechsel" und "Wechsel ohne DSL im Bestand".
          Es werden nur erfasste Aufträge gezählt. Wir betrachten immer den aktuellen Kalendermonat.
        </p>
        <h3>Dein offener Umsatz:</h3>
        <p>
          Angezeigt wird die mögliche Provisionssumme aller deiner offenen Aufträge. Gezählt werden alle Aufträge im Status "Offen (In Rücksprache)" .
          Tipp: Über die Auftragsliste kannst du die Aufträge herausfiltern und ggf. nacharbeiten. Wir betrachten Aufträge aus den letzten 31 Tagen.
        </p>
      </b-modal>
        <b-row class="mb-3 mt-3">
            <b-col md="12" class="text-right">
                <b-alert variant="error" v-if="viewstate"></b-alert>
            </b-col>
        </b-row>
        <b-row class="mb-3 mt-3">
            <b-col md="12" class="text-right">
                <h3> {{ userName() }} </h3>
                <p v-if="isUserVm()"> {{ userCurrentLevel }}</p>
            </b-col>
        </b-row>
        <b-row class="mb-3 mt-3">
            <b-col md="12" class="text-center">
                <h1>Mein Tag</h1>
            </b-col>
        </b-row>
        <b-row class="text-center align-items-end">
            <b-col sm="12" md="3" class="text-right">
              <h2><strong>{{ new Date() | formatDate }}</strong></h2>
              <h6>{{ currentTime() }}</h6>
            </b-col>
            <b-col sm="12" md="6">
                <Tacho
                    :aktueller-wert="umsatzanzeige.tag.aktuellerWert"
                    :maximaler-wert="umsatzanzeige.tag.maximalerWert"
                    :baseColor="'#e7e8db'"
                    :skalierung="umsatzanzeige.tag.maximalerWert/12"
                    :schritt-weite="100"
                    :suffix="'€'"/>
            </b-col>
            <b-col sm="12" md="3" class="text-md-left text-center">
              <h2><strong>{{ umsatzanzeige.tag.aktuellerWert }} &euro;</strong></h2>
              <h6>Bruttoumsatz</h6>
            </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <motivationstext :aktueller-wert="umsatzanzeige.tag.aktuellerWert"/>
          </b-col>
        </b-row>
        <b-row class="text-center boxes">
            <b-col md="6" sm="12" style="" class="">
                <div class="px-5 mt-4 mb-5">
                    <b-row>
                        <b-col>
                            <h4>Meine Woche</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-left">
                            <b-row>
                                <b-col>
                                    <label>Bruttoumsatz</label>
                                </b-col>
                            </b-row>
                            <umsatzanzeige :aktueller-wert="umsatzanzeige.woche.aktuellerWert"
                                           :maximaler-wert="umsatzanzeige.woche.maximalerWert" />
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="6" sm="12">
                <div class="px-5 mt-4 mb-5">
                    <b-row>
                        <b-col>
                            <h4>Mein Monat</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-left">
                            <b-row>
                                <b-col>
                                    <label>Bruttoumsatz</label>
                                </b-col>
                            </b-row>
                            <umsatzanzeige :aktueller-wert="umsatzanzeige.monat.aktuellerWert"
                                           :maximaler-wert="umsatzanzeige.monat.maximalerWert" />
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <b-row class="text-center boxes">
          <b-col md="6" sm="12">
            <div class="px-5 mt-4 mb-5">
              <circle-anzeige :progress="stornoQuote" headline="Deine Stornoquote" :legendCaption="stornoQuoteLegendCaption" :circleColor="stornoColor"/>
            </div>
          </b-col>
          <b-col md="6" sm="12">
            <div class="px-5 mt-4 mb-5">
              <circle-anzeige :progress="kwAnteilWert" headline="Dein KW Anteil" :circleColor="kwAnteilColor"/>
            </div>
          </b-col>
        </b-row>
        <b-row class="text-center boxes">
            <b-col md="6" sm="12">
                <div class="px-5 mt-4 mb-5">
                    <b-row>
                        <b-col>
                            <h4>Deine offenen Ums&auml;tze</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <h2><strong>{{umsatzanzeige.offen}} &euro;</strong></h2>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col md="6" sm="12">
                <div class="px-5 mt-4 mb-5">
                    <b-row>
                        <b-col>
                            <h4>Deine Auftragsliste</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button variant="primary" :to="{ name: 'auftragsliste'}">Zur Auftragsliste</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
        <b-button v-b-modal="'info-modal'" class="info-button">
          <img src="/css/grafik/i2.png" />
        </b-button>
    </div>
</template>

<script>
  import Tacho from '../common/Tacho'
  import Umsatzanzeige from '../common/Umsatzanzeige'
  import { mapGetters } from 'vuex'
  import {mapActions, mapState} from 'vuex'
  import umsatz from '../../api/kpi-energie-api/umsatz'
  import Motivationstext from '@/components/common/Motivationstext'
  import CircleAnzeige from "@/components/common/CircleAnzeige"
  import karriere from "@/api/kpi-energie-api/karriere";

  export default {
    name: 'PageDashboard',
    components: {Motivationstext, Umsatzanzeige, Tacho, CircleAnzeige},
    computed: {
      ...mapState({
        viewstate: state => state.viewstate.viewstate
      }),
      stornoQuoteLegendCaption() {
        return "Vormonat: " + this.stornoQuoteVormonat + "%";
      },
      stornoColor() {
        return this.stornoQuote >= 15 ? "#af1900" : (this.stornoQuote >= 10 ? "#fccb00" : "#3faf00");
      },
      kwAnteilWert() {
        return this.kwAnteil < 0 ? 0 : this.kwAnteil
      },
      kwAnteilColor() {
        return this.kwAnteil < 0 ? "#262626" : (this.kwAnteil > 66 ? "#3faf00" : (this.kwAnteil > 33 ? "#fccb00" : "#af1900"));
      },
    },
    data () {
      return {
        umsatzanzeige: {
          tag: {
            aktuellerWert: 0,
            maximalerWert: 600
          },
          woche: {
            aktuellerWert: 0,
            maximalerWert: 2500
          },
          monat: {
            aktuellerWert: 0,
            maximalerWert: 8000
          },
          offen: 0,
        },
        kwAnteil: -1,
        stornoQuote: 0,
        stornoQuoteVormonat: 0,
        userCurrentLevel: '',
      }
    },
    methods: {
      ...mapActions({
        setState: 'viewstate/setState'
      }),
      ...mapGetters({
        getUser: 'user/getUser'
      }),
      currentTime () {
        const time = new Date()
        return  ("0" + time.getHours()).slice(-2) + ":" + ("0" + time.getMinutes()).slice(-2) + ' Uhr'
        },
      userName() {
        const user = this.getUser()
        return user.vorname + " " + user.nachname
      },
      currentDate () {
        return new Date()
      },
      userLevel() {
        karriere.getKarrierestufe()
        .then((response) => {
          this.userCurrentLevel = response.data;
        });
      },
      isUserVm() {
        const user = this.getUser();
        return user.type == 'VM';
      },
    },
    created() {
      this.setState({loading: true, messageType: this.viewstate.messageType, message: this.viewstate.message})
      this.userLevel();
      umsatz.ladeDashboard()
        .then(response => {
          this.umsatzanzeige.tag.aktuellerWert = response.data.tagesUmsatz
          this.umsatzanzeige.woche.aktuellerWert = response.data.wochenUmsatz
          this.umsatzanzeige.monat.aktuellerWert = response.data.monatsUmsatz
          this.umsatzanzeige.offen = response.data.offenerUmsatz
          this.kwAnteil = response.data.kwAnteil
          this.stornoQuote = response.data.stornoQuote
          this.stornoQuoteVormonat = response.data.stornoQuoteVormonat
        })
        .finally(() => {
          this.setState({loading: true, messageType: this.viewstate.messageType, message: this.viewstate.message})
        })
    }
  }
</script>

<style scoped>
  .info-button {
    color: #005783;
    border: none;
    background: transparent;
  }
</style>

