import HTTP from '../http-common'

export default {
  holeAuftraege(filter) {
    return HTTP.post(`/kpi/auftrag`, filter)
  },
  holeMonate() {
    return HTTP.get(`/kpi/auftrag/monate`)
  },
  holeGeschaeftsfaelle() {
    return HTTP.get(`/kpi/auftrag/geschaeftsfaelle`)
  },
  holeMitarbeiterAuftraege(filter) {
    return HTTP.post('/kpi/auftrag/getMitarbeiterAuftraege', filter)
  },
  holeMitarbeiterMonate() {
    return HTTP.get('/kpi/auftrag/getMitarbeiterMonate')
  },
  holeMitarbeiterNamen() {
    return HTTP.get('/kpi/auftrag/getMitarbeiterNamen')
  }
}
