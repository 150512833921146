import * as types from '../mutation-types'

// initial state
const state = {
  token: null
}

// getters
const getters = {
  getUser: state => {
    let user = null
    let payload = getPayload(state.token)
    if (payload) {
      user = {
        id: payload.psopay.userId,
        anrede: payload.psopay.anrede,
        vorname: payload.psopay.vorname,
        nachname: payload.psopay.nachname,
        type: payload.psopay.userType,
        nickname: payload.psopay.nickname,
        diyplayName: payload.psopay.displayName,
        email: payload.psopay.email,
        karriereId: payload.psopay.karriereleiter_id,
      }
    }
    return user
  },
  getLogoutTimestamp: state => {
    let timestamp = null
    let payload = getPayload(state.token)
    if (payload) {
      timestamp = payload.exp
    }
    return timestamp
  },
  getSession: state => {
    let session = null
    let payload = getPayload(state.token)
    if (payload) {
      session = payload.psopay.session
    }
    return session
  },
}

const getPayload = (token) => {
  let payload = null
  if (token) {
    let jwt = token.split('.')
    if (jwt.length === 3) {
      let payload_str = atob(jwt[1])
      payload = JSON.parse(payload_str)
    }
  }
  return payload
}

// actions
const actions = {
  setToken({commit}, token) {
    commit(types.CHANGE_USER_TOKEN, token)
  },
  setUser({commit}, user) {
    commit(types.CHANGE_USER_DATA, user)
  },
}

// mutations
const mutations = {
  [types.CHANGE_USER_TOKEN](state, token) {
    state.token = token
  },
  [types.CHANGE_USER_DATA](state, user) {
    state.user = user
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
