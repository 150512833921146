import * as types from '../mutation-types'

export const MESSAGE_TYPE_INFO = 1
export const MESSAGE_TYPE_SUCCESS = 2
export const MESSAGE_TYPE_ERROR = 3

// initial state
const state = {
  viewstate: {
    loading: false,
    messageType: MESSAGE_TYPE_INFO,
    message: ''
  }
}

// getters
const getters = {}

// actions
const actions = {
  setState({commit}, {loading, messageType, message}) {
    commit(types.CHANGE_APPLICATION_STATE, {loading, messageType, message})
  }
}

// mutations
const mutations = {
  [types.CHANGE_APPLICATION_STATE](state, {loading, messageType, message}) {
    state.viewstate = {loading, messageType, message}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
