<template>
  <div>
    <b-row v-if="!isWebview()">
      <b-col>
        <b-button class="float-right" download="Auftragsliste" @click="exportAuftragsliste">
          Auftragsliste als Excel-Datei herunterladen
        </b-button>
      </b-col>
    </b-row>
    <common-table ref="auftragsTabelle"
                  :fields="fields"
                  :busy="viewstate.loading"
                  :sortBy="sortBy"
                  :dataProvider="holeAuftraege"
                  :usePagination="false"
                  :useClientSorting="true"
                  :other-filters-size="6"
                  show-empty
    >
      <template v-slot:beforeSearchFilter="filterMutations">
        <auftragsmonat-filter style="float: left; width: 20%" :filterMutations="filterMutations"/>
        <geschaeftsfall-filter style="float: left; width: 45%" :filterMutations="filterMutations"/>
      </template>
      <template v-slot:empty>
        <h4 class="py-3 text-center">
          Keine Aufträge gefunden.
        </h4>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(auftragsdatum)="row">
        {{ row.item.auftragsdatum | formatDate }}
      </template>
      <template #cell(umsatzCent)="row">
        {{ row.item.umsatzCent | centsToEuros }}
      </template>
    </common-table>
  </div>
</template>

<script>
import CommonTableMixin from '@pso/vue-shared/mixins/componentRelation/commonTable'
import GeschaeftsfallFilter from '../../components/common/GeschaeftsfallFilter'
import AuftragsmonatFilter from '../../components/common/AuftragsmonatFilter'
import auftrag from '../../api/kpi-energie-api/auftrag'
import {mapActions, mapState} from "vuex"
import {
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
} from "../../store/modules/viewstate"
import exportFromJSON from "export-from-json";

export default {
  name: 'Auftragstabelle',
  mixins: [CommonTableMixin],
  components: {GeschaeftsfallFilter, AuftragsmonatFilter},
  computed: {
    ...mapState({
      viewstate: (state) => state.viewstate.viewstate,
    }),
  },
  data() {
    return {
      fields: [
        {key: 'auftragsnummer', label: 'Vorgangsnummer', sortable: true},
        {key: 'auftragsdatum', label: 'Datum', sortable: true},
        {key: 'geschäftsfall', label: 'Geschäftsfall', sortable: true},
        {key: 'produkt', label: 'Produkt', sortable: true},
        {key: 'status', label: 'Status', sortable: true},
        {key: 'stornogrund', label: 'Stornogrund', sortable: true},
        {key: 'umsatzCent', label: 'Basisprovision', sortable: true},
      ],
      sortBy: 'auftragsdatum',
      filteredOrders: []
    }
  },
  methods: {
    ...mapActions({
      setState: "viewstate/setState",
    }),
    holeAuftraege(filter) {
      this.setState({
        loading: true,
        messageType: this.viewstate.messageType,
        message: this.viewstate.message,
      })
      return auftrag.holeAuftraege(filter)
          .then((response) => {
            this.setState({
              loading: this.viewstate.loading,
              messageType: MESSAGE_TYPE_SUCCESS,
              message: "",
            })
            this.filteredOrders = response.data
            return response
          })
          .catch(() => {
            this.setState({
              loading: this.viewstate.loading,
              messageType: MESSAGE_TYPE_ERROR,
              message: "",
            })
          })
          .finally(() => {
            this.setState({
              loading: false,
              messageType: this.viewstate.messageType,
              message: this.viewstate.message,
            })
          })
    },
    exportAuftragsliste() {
      const data = this.filteredOrders.map(order => {
        let ele = {}
        this.fields.forEach(field => {
          if (field.key === 'auftragsdatum') {
            ele[field.label] = new Date(Date.parse(order[field.key])).toLocaleDateString(undefined, {dateStyle: 'medium'})
          } else {
            ele[field.label] = order[field.key]
          }
        })
        return ele
      })
      const fileName = 'Auftragsliste'
      const exportType = exportFromJSON.types.csv
      const delimiter = ';'
      const withBOM = true
      exportFromJSON({
        data,
        fileName,
        exportType,
        delimiter,
        withBOM
      })
    },
    isWebview() {
      let standalone = window.navigator.standalone,
          userAgent = window.navigator.userAgent.toLowerCase(),
          safari = /safari/.test(userAgent),
          ios = /iphone|ipod|ipad/.test(userAgent)

      if (ios && !standalone && !safari) {
        return true
      }
      if (!ios && userAgent.includes('wv')) {
        return true
      }

      return false
    },
  }
}
</script>

<style scoped>
</style>