<template>
  <div class="vertriebspartner-filter">
    <b-form-group label="Partner" :label-cols="11">
      <b-form-select v-model="selectedPartner"
                     :options="partners"
                     :plain="true"
                     @change="updateFilter"/>
    </b-form-group>
  </div>
</template>

<script>
import Vue from 'vue'
import {FormGroupPlugin, FormSelectPlugin} from 'bootstrap-vue'
import provision from '../../api/kpi-energie-api/provision'

Vue.use(FormGroupPlugin)
Vue.use(FormSelectPlugin)

const KEINE_PARTNER = 'Keine Partner vorhanden!'

export default {
  name: 'VertriebspartnerFilter',
  props: {
    filterMutations: {
      type: Object,
      required: true
    },
    beforeChange: {
      type: Function,
      required: false
    },
  },
  data() {
    return {
      partners: null,
      selectedPartner: null,
      oldSelectedPartner: null
    }
  },
  methods: {
    updateFilter(val) {
      if (!this.beforeChange || this.beforeChange()) {
        this.filterMutations.remove('VP')
        if (val.vertriebspartnername !== KEINE_PARTNER) {
          this.filterMutations.add({Name: 'VP', Value: val.vertriebspartnerId.toString()})
        }
        this.filterMutations.update()
        this.oldSelectedPartner = this.selectedPartner
      } else {
        this.selectedPartner = this.oldSelectedPartner
      }
    }
  },
  created() {
    provision.ladeProvisionVertriebspartner()
        .then(response => {
          this.partners = response.data
          if (response.data.length === 0) {
            this.partners.unshift({
              value: {
                vertriebspartnerId: 0,
                vertriebspartnernummer: '',
                vertriebspartnername: KEINE_PARTNER
              }, text: KEINE_PARTNER
            })
          }
          this.selectedPartner = this.partners[0].value
          this.updateFilter(this.selectedPartner)
        })
  }
}
</script>

<style scoped>
</style>
