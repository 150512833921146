export const CHANGE_APPLICATION_STATE = 'CHANGE_APPLICATION_STATE'
export const CHANGE_USER_TOKEN = 'CHANGE_USER_TOKEN'
export const CHANGE_USER_DATA = 'CHANGE_USER_DATA'
export const CHANGE_PROVISION = 'CHANGE_PROVISION'
export const CHANGE_PROVISION_GESCHAEFTSFAELLE = 'CHANGE_PROVISION_GESCHAEFTSFAELLE'
export const CHANGE_PROVISION_SELECTED_GESCHAEFTSFALL = 'CHANGE_PROVISION_SELECTED_GESCHAEFTSFALL'
export const CHANGE_PROVISION_SELECTED_KATEGORIE = 'CHANGE_PROVISION_SELECTED_KATEGORIE'
export const CHANGE_PROVISION_FILTERS = 'CHANGE_PROVISION_FILTERS'
export const CHANGE_PROVISION_CENTPROVISION = 'CHANGE_PROVISION_CENTPROVISION'
export const CHANGE_PROVISION_CHANGES = 'CHANGE_PROVISION_CHANGES'
export const CLEAR_PROVISION_CHANGES = 'CLEAR_PROVISION_CHANGES'
export const CHANGE_KARRIERE = 'CHANGE_KARRIERE'
export const CHANGE_KARRIERE_CHANGES = 'CHANGE_KARRIERE_CHANGES'
export const CLEAR_KARRIERE_CHANGES = 'CLEAR_KARRIERE_CHANGES'
export const CHANGE_KARRIERE_FILTERS = 'CHANGE_KARRIERE_FILTERS'
export const CHANGE_KARRIERE_LEVEL = 'CHANGE_KARRIERE_LEVEL'
export const CHANGE_TEAM_MITARBEITER = 'CHANGE_TEAM_MITARBEITER'
export const CHANGE_SELECTED_TEAMLEITER = 'CHANGE_SELECTED_TEAMLEITER'
export const CHANGE_TEAM_MITGLIEDER = 'CHANGE_TEAM_MITGLIEDER'
export const CHANGE_TEAM_MITGLIEDER_LIST = 'CHANGE_TEAM_MITGLIEDER_LIST'
export const CHANGE_TEAM_MITGLIED_STATUS = 'CHANGE_TEAM_MITGLIED_STATUS'
export const CHANGE_TEAM_MITGLIEDER_CHANGES = 'CHANGE_TEAM_MITGLIEDER_CHANGES'
