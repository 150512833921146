import HTTP from '../http-common'

export default {
  holeProvisionen (filter) {
    return HTTP.post(`/kpi/provision`, filter)
  },
  speichereProvisionen(daten, filter) {
    return HTTP.put(`/kpi/provision`, daten, filter)
  },
  holeProvisionslog (filter) {
    return HTTP.post(`/kpi/provision/changelog`, filter)
  },
  provisionVerwaltungErlaubt () {
    return HTTP.get(`/kpi/provision/verwaltung`)
  },
  ladeProvisionVertriebspartner() {
    return HTTP.get(`/kpi/provision/vertriebspartner`)
  },
  istVerwaltungFirma() {
    return HTTP.get(`/kpi/provision/verwaltungFirma`)
  }
}
