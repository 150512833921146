import Vue from 'vue'
import VueRouter from 'vue-router'
import PageDashboard from '../components/pages/PageDashboard'
import PageProvisionsverwaltung from '../components/pages/PageProvisionsverwaltung'
import PageProvisionslog from '../components/pages/PageProvisionslog'
import PageAuftragsliste from '../components/pages/PageAuftragsliste'
import PageMitarbeiterStatistik from '../components/pages/PageMitarbeiterStatistik'
import PageLogout from '../components/pages/PageLogout'
import store from '../store'
import MvTokenRefresh from '../api/mv-api/mv-token-refresh'
import PageKarriereleiter from "@/components/pages/PageKarriereleiter";
import PageMitarbeiterAuftragsliste from "../components/pages/PageMitarbeiterAuftragsliste";
import PageMitarbeiteraktivitaet from "@/components/pages/PageMitarbeiteraktivitaet"
import PageSperrgebieteExport from "../components/pages/PageSperrgebieteExport";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: PageDashboard
  },
  {
    path: '/provisionsverwaltung',
    name: 'provisionsverwaltung',
    component: PageProvisionsverwaltung
  },
  {
    path: '/auftragsliste',
    name: 'auftragsliste',
    component: PageAuftragsliste
  },
  {
    path: '/mitarbeiterstatistik',
    name: 'mitarbeiterstatistik',
    component: PageMitarbeiterStatistik
  },
  {
    path: '/provisionslog',
    name: 'provisionslog',
    component: PageProvisionslog
  },
  {
    path: '/karriereleiter',
    name: 'karriereleiter',
    component: PageKarriereleiter
  },
  {
    path: '/mitarbeiterauftragsliste',
    name: 'mitarbeiterauftragsliste',
    component: PageMitarbeiterAuftragsliste
  },
  {
    path: '/mitarbeiteraktivitaet',
    name: 'mitarbeiteraktivitaet',
    component: PageMitarbeiteraktivitaet
  },
  {
    path: '/sperrgebieteexport',
    name: 'sperrgebieteexport',
    component: PageSperrgebieteExport
  },
  {
    path: '/logout',
    name: 'logout',
    component: PageLogout
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let now = parseInt(Date.now() / 1000) ?? 700
  let timestamp = store.getters['user/getLogoutTimestamp'] ?? 300

  let g = new Date(now*1000).toLocaleString("de-DE")
  let t = new Date(timestamp*1000).toLocaleString("de-DE")

  // Wenn Logout, Entwicklungsumgebung oder Übernahme des Token, dann kein Tokencheck
  if (to.fullPath === '/logout' || to.query.t || (process.env.VUE_APP_TOKEN && process.env.NODE_ENV !== 'production')) {
    next()
    return
  }

  // Token noch gültig, aber 10 min. Grenze erreicht
  if (now >= timestamp-50*60) {
    // Token holen
    MvTokenRefresh.getRefreshedToken(store.state.user.token)
      .then(response => {
        let data = response.data
        if (data == null) {
          throw new TypeError('Wrong answer')
        }
        store.dispatch('user/setToken', data)
        next()
      }).catch((error) => {
        next('/logout')
    })
  } else if (now > timestamp) {
    // Token abgelaufen
    next('/logout')
  } else {
    // Token gültig
    next()
  }

})
export default router
