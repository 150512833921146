import * as types from '../mutation-types'
import karriere from "@/api/kpi-energie-api/karriere";
import store from '../index'
import {MESSAGE_TYPE_INFO} from "@/store/modules/viewstate";


const state = {
    karriereChanges: {},
    karrieren: [],
    apiResponse: null,
    filters: [],
}

const getters = {
    changedKarriereByKey: state => key => state.karriereChanges[key],
}

const actions = {
    setUpdated({commit}, entry) {
        commit(types.CHANGE_KARRIERE_CHANGES, entry);
    },
    setLevel({commit}, data) {
        commit(types.CHANGE_KARRIERE_LEVEL, data);
    },
    resetChanges({commit, state}, caller) {
        commit(types.CLEAR_KARRIERE_CHANGES, state);
    },
    setFilters({commit}, entry) {
        commit(types.CHANGE_KARRIERE_FILTERS, [...entry]);
    },
    setKarriereList({commit, state}, params) {
        store.dispatch('viewstate/setState', {
            loading: true,
            messageType: MESSAGE_TYPE_INFO,
            message: 'Mitarbeiter werden geladen'
        })

        if(state.apiResponse !== null) {
            return state.apiResponse;
        }

        return karriere
            .holeMitarbeiterKarrieren(params.filters)
            .then((response) => {
                let data = response.data.reduce((a,b) => {
                    return {
                        ...a,
                        [b.key]: b
                    }
                }, {});
                commit(types.CHANGE_KARRIERE, data);
                return response;
            })
            .catch((error) => {
                params.caller.$bvToast.toast(`Fehler beim Laden der Mitarbeiter: ${error.message}`, {
                    variant: 'danger',
                    autoHideDelay: 3000,
                    appendToast: false
                })
            })
            .finally(() => {})
    },
    saveChanges({commit, state }, caller) {
        const data = Object.keys(state.karriereChanges).map(
            c => { return {
                key: c,
                level: state.karriereChanges[c]
                }
            });
            karriere.aktualisiereKarrieren(data)
                .then(c => {
                    if(c.status == 200) {
                        caller.$bvToast.toast('Änderungen erfolgreich gespeichert', {
                            variant: 'success',
                            autoHideDelay: 3000,
                            appendToast: false
                        })
                        for(let index = 0; index < data.length; index++) {
                            const changes = data[index];
                            commit(types.CHANGE_KARRIERE_LEVEL, {key: changes.key, level: changes.level});
                        }
                        commit(types.CLEAR_KARRIERE_CHANGES)
                    }
                })
                .catch(error => {
                    caller.$bvToast.toast(`Fehler beim Speichern der Änderungen: ${error.message}`, {
                        variant: 'danger',
                        autoHideDelay: 3000,
                        appendToast: false
                    })
                })
    },
}

const mutations = {
    [types.CHANGE_KARRIERE_CHANGES](state, data) {
        let tmp = {...state.karriereChanges}
        let neuerWertIstOriginal = data.level === state.karrieren[data.key].level;
        if(neuerWertIstOriginal && tmp[data.key]) {
            tmp[data.key] = null;
            delete tmp[data.key];
        }
        else if(!neuerWertIstOriginal) {
            tmp[data.key] = data.level;
        }
        state.karriereChanges = tmp;
    },
    [types.CHANGE_KARRIERE](state, data) {
        state.karrieren = data;
    },
    [types.CHANGE_KARRIERE_FILTERS](state, data) {
        state.filters = data;
    },
    [types.CHANGE_KARRIERE_LEVEL](state, data) {
        let newKarriere = {...state.karrieren}
        newKarriere[data.key].level = data.level;
        state.karrieren = newKarriere;
    },
    [types.CLEAR_KARRIERE_CHANGES](state) {
        state.karriereChanges = {}
    },
    [types.CHANGE_KARRIERE_FILTERS](state, data) {
        state.filters = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}