<template>
    <b-container>
        <b-row class="justify-content-md-center mb-3" sm="6"/>
        <b-row class="justify-content-md-center mb-3" sm="6">
            <b-col md="6">
                <b-form-group label="Datum" :label-cols="12">
                    <b-input-group>
                        <b-datepicker v-model="selectedDate"/>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col :md="'6'">
                <b-form-group label="Mitarbeiterfilter" :label-cols="12">
                <b-input-group>
                    <b-form-input v-model="filterString" placeholder="Suchen"
                                v-on:input="delayedLoadData()"/>
                    <b-button variant="primary" @click="removeAllFilters">Filter
                    löschen
                    </b-button>
                </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="justify-content-md-center mb-3" sm="6">
            <b-table :items="mitarbeiterAktivitaetWithStyle" :fields="fields" @row-clicked="onRowClicked">
            </b-table>
        </b-row>
        <mitarbeiter-aktivitaet-modal v-if="showModal" ref="modal" :workerData="selectedWorker" :date="selectedDateDate" :closeCallback="closeModal">
        </mitarbeiter-aktivitaet-modal>
    </b-container>
</template>

<script>
import api from '../../api/kpi-energie-api/team'
import MitarbeiterAktivitaetModal from '../common/MitarbeiteraktivitaetModal.vue'

  export default {
    name: 'PageMitarbeiteraktivitaet',
    components: { MitarbeiterAktivitaetModal },
    data() { 
        return {
            isCooldownRunning: false,
            cooldownSeconds: 0,
            selectedDate: new Date().toISOString().split('T')[0],
            filterString: null,
            selectedWorker: null,
            fields: [
                { 
                    label: '',
                    key: 'name'
                }, 
                {
                    label: 'Erste Verfügbarkeit',
                    key: 'firstAvaiable',
                    variant: 'primary',
                    sortable: true
                }, 
                {
                    label: 'Letzte Verfügbarkeit',
                    key: 'lastAvaiable',
                    sortable: true
                }, 
                {
                    label: 'Letzter Auftrag',
                    key: 'lastContract',
                    sortable: true
                }, 
                {
                    label: 'Zeit seit letztem Auftrag',
                    key: 'timeSinceLastContract',
                    sortable: true
                }
            ],
            showModal: false,
            mitarbeiterAktivitaet: []
        }
    },
    computed: {
        mitarbeiterAktivitaetWithStyle() {
            return this.mitarbeiterAktivitaet.map(x => {
                x._cellVariants = { 
                    timeSinceLastContract: this.timeSinceLastContractvariant(x.timeSinceLastContract)
                }
                x.firstAvaiable = this.toTimeString(x.firstAvaiable)
                x.lastAvaiable = this.toTimeString(x.lastAvaiable)
                x.lastContract = this.toTimeString(x.lastContract)
                return x
                })
        },
        selectedDateDate() {
            return new Date(this.selectedDate)
        }
    },
    metaInfo() {
      return {
          title: 'mitarbeiteraktivität'
      }
    },
    watch: {
        selectedDate: {
            handler(val, oldVal) {
                api.holeMitarbeiterActivity([], val).then(data => {
                    this.mitarbeiterAktivitaet = data.data
                })
            }
        }
    },
    methods: {
        timeSinceLastContractvariant: value => (value > '02:00' ? 'danger' : null),
        loadData() {
            api.holeMitarbeiterActivity(this.filterString ? [{ name: 'Name', value: this.filterString }] : [], this.selectedDate).then(data => {
                if (data.status === 200) {
                    this.mitarbeiterAktivitaet = data.data
                } else {                    
                    this.mitarbeiterAktivitaet = []
                }
            })
        },
        closeModal() {
            this.showModal = false
        },
        toTimeString(date) {
            return date ? date.split('T')[1].split('.')[0] : ''
        },
        removeAllFilters() {
            this.filterString = ''
            this.loadData()
        },
        sleep(milliseconds) {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        },
        async delayedLoadData() {
            this.cooldownMilliseconds = 500
            if (!this.isCooldownRunning) {
                this.isCooldownRunning = true
                while (this.cooldownMilliseconds > 0) {
                    await this.sleep(100)
                    this.cooldownMilliseconds -= 100
                }
                this.loadData()
                this.isCooldownRunning = false
            }
        },
        async onRowClicked(data, index) {
            this.selectedWorker = data
            this.showModal = true
            await this.sleep(100)
            this.$refs['modal'].show(this.selectedDate)
        }
    },
    mounted() {
        this.loadData()
    }
  }
</script>

<style scoped>
</style>
