<template>
  <div>
    <!--
    <VueSvgGauge
        :start-angle="-85"
        :end-angle="85"
        :min="0"
        :separatorThickness="1"
        :innerRadius="70"
        :baseColor=baseColor
        :value=aktuellerWert
        :separator-step="schrittWeite"
        :max=maximalerWert
        :gauge-color=gaugeColor
        :scale-interval=skalierung
    >
      <div class="inner-text">
        <span :style="{'color': textColor }">{{ aktuellerWert }}{{ suffix }}</span>
      </div>
    </VueSvgGauge>
    -->

    <vue-speedometer
        :width="400"
        :height="212"
        :needleHeightRatio="0.6"
        :maxSegmentLabels="0"
        :customSegmentStops="[0, 100, 250, 350, 550, 600]"
        :segmentColors='["#d6083b", "purple", "darkblue", "#18589b", "#8dc7e9"]'
        :value=aktuellerWertMax
        :minValue="0"
        :maxValue="600"
        :needleColor="'#0B3B7D'"
        textColor="'#000'"
        :needleTransitionDuration="4000"
        :segments="50"
        needleTransition="easeCircleIn"
        currentValueText=""
    />
  </div>
</template>

<script>
// import { VueSvgGauge } from 'vue-svg-gauge'
import VueSpeedometer from 'vue-speedometer'

export default {
  name: 'Tacho',
  components: {VueSpeedometer},
  props: {
    maximalerWert: {
      type: Number,
      required: true
    },
    aktuellerWert: {
      type: Number,
      required: true
    },
    suffix: {
      type: String,
      default: ''
    },
    baseColor: {
      type: String,
      default: '#e7e8db'
    },
    textColor: {
      type: String,
      default: '#85cae7'
    },
    gaugeColor: {
      type: Array,
      default: () => [{offset: 0, color: '#4ea8ee'}, {offset: 100, color: '#347AB0'}]
    },
    skalierung: {
      type: Number,
      default: 50
    },
    schrittWeite: {
      type: Number,
      default: 150
    }
  },
  computed: {
    aktuellerWertMax() {
      return this.aktuellerWert < 600 ? this.aktuellerWert : 600
    }
  }

}
</script>

<style scoped>
.inner-text {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 70px;
}

.inner-text span {
  font-weight: bold;
  max-width: 100px;
}
</style>
