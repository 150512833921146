import HTTP from '../http-common'

export default {
    holeMitarbeiterKarrieren(filter) {
        return HTTP.post('/kpi/karriere/mitarbeiterKarriere', filter)
    },
    holeKarrierestufen() {
        return HTTP.get('/kpi/karriere/karrierestufen')
    },
    aktualisiereKarrieren(data) {
        return HTTP.post('/kpi/karriere/updateMitarbeiterKarriere', data)
    },
    darfKarriereleiterVerwalten() {
        return HTTP.get('/kpi/karriere/darfKarriereleiterVerwalten')
    },
    getKarrierestufe() {
        return HTTP.get('/kpi/karriere/getKarrierestufe')
    },
    darfMitarbeiterListeEinsehen() {
        return HTTP.get('/kpi/karriere/darfMitarbeiterListeSehen')
    }
}