<template>
    <b-row>
        <b-col>
            <b-row class="py-3">
                <b-col>
                    <h1>Mitarbeiter Statistiken</h1>
                </b-col>
            </b-row>
            <b-row class="py-3">
                <b-col>
                    <mitarbeiterStatistikTabelle ref="mitarbeiterStatistikTabelle" />
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
  import MitarbeiterStatistikTabelle from '../common/MitarbeiterStatistikTabelle'

  export default {
    name: 'PageMitarbeiterStatistik',
    components: { MitarbeiterStatistikTabelle},
    metaInfo() {
      return {
          title: 'MitarbeiterStatistik'
      }
    },
  }
</script>

<style scoped>
</style>
