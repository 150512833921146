<template>
  <div>
    <div class="text-center sticky py-3">
      <b-button class="mr-3"
                :variant="changedItems.length > 0 ? 'secondary' : 'info'"
                size="lg"
                v-b-modal.save-teams
                :disabled="saveButtonDisabled"
      >Änderungen speichern</b-button>
      <b-modal id="save-teams" size="lg" title="Änderungen speichern" cancel-title="Abbrechen" @ok="save">
        <p class="my-4">
          Sicher, dass diese Änderungen gespeichert werden sollen?
        </p>
      </b-modal>
    </div>
    <b-button @click="showHideDescription"> {{ descriptionTitle }}</b-button>
    <div v-if="descriptionVisible">
      <p>Hier kannst du die Teamverteilung deiner Mitarbeiter verwalten</p>
      <p>In der linken Spalte kannst du den Leiter eines Teams auswählen</p>
      <p>In der rechten Spalte kannst du dann die dazugehörigen Teammitglieder auswählen</p>
    </div>
    <b-row class="py-3">
      <b-col>
        <common-table :fields="fields"
                      :busy="viewstate.loading"
                      :sort-by="sortBy"
                      :use-pagination="false"
                      :use-client-sorting="true"
                      :use-sticky="false"
                      :data-provider="holeTeamMitarbeiter"
                      show-empty>
          <template v-slot:empty>
            <h4 class="py-3 text-center">
              Keine aktiven Mitarbeiter gefunden
            </h4>
          </template>
          <template #cell(selected)="row">
            <b-form-checkbox :key="row.item.key"
                             :checked="row.item.selected"
                @change="selectTeamleiter(row.item)"
            />
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
        </common-table>
      </b-col>
      <b-col>
        <common-table :fields="mitgliederFields"
                      :busy="viewstate.loading"
                      :sort-by="sortBy"
                      :use-pagination="false"
                      :use-client-sorting="false"
                      :use-sticky="false"
                      :objects="teamMitgliederList"
                      :disable-filter="true"
                      show-empty>
          <template v-slot:empty>
            <h4 class="py-3 text-center">
              Bitte einen Teamleiter auswählen
            </h4>
          </template>
          <template #cell(selected)="row">
            <b-form-checkbox :key="row.item.key"
                             :checked="row.item.selected"
                             @change="changeTeamMitgliedStatus($event, row.item)"
            />
          </template>
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
        </common-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CommonTableMixin from '@pso/vue-shared/mixins/componentRelation/commonTable'
import team from "../../api/kpi-energie-api/team";
import {mapActions, mapState, mapGetters} from "vuex"

export default {
  name: "TeamTabelle",
  mixins: [CommonTableMixin],
  computed: {
    ...mapState({
      viewstate: state => state.viewstate.viewstate,
      teamMitarbeiter: state => state.team.teamMitarbeiter,
      teamMitglieder: state => state.team.teamMitglieder,
      teamMitgliederList: state => state.team.teamMitgliederList,
      changedTeammitglieder: state => state.team.changedTeammitglieder,
    }),
    ...mapGetters({
      changedTeammitgliedByKey: 'team/changedTeammitgliedByKey',
    }),
    changedItems() {
      return Object.keys(this.changedTeammitglieder)
      .filter(c => this.teamMitglieder[c] && this.changedTeammitglieder[c])
      .map(c => {
        return {
          key: c,
          isInTeam: this.changedTeammitglieder[c]
        }
      });
    },
    saveButtonDisabled() {
      return this.changedTeammitglieder.length === 0;
    }
  },
  data() {
    return {
      fields: [
        {key: 'name', label: 'Name', sortable: true},
        {key: 'key', label: 'Partnernummer', sortable: true},
        {key: 'selected', label: 'Auswahl'},
      ],
      mitgliederFields: [
        {key: 'name', label: 'Name'},
        {key: 'key', label: 'Partnernummer'},
        {key: 'selected', label: 'Teammitglied'},
      ],
      sortBy: 'name',
      descriptionVisible: false,
      descriptionTitle: 'Beschreibung anzeigen',
    }
  },
  methods: {
    ...mapActions({
      setTeamMitarbeiter: 'team/setMitarbeiterList',
      setTeamMitglieder: 'team/setTeamMitgliederList',
      clearTeammitgliederList: 'team/clearTeamMitgliederList',
      clearTeammitgliederChanges: 'team/clearTeammitgliederChanges',
      setSelectedTeamleiter: 'team/setNewSelectedTeamleiter',
      setUpdated : 'team/setUpdated',
      saveTeammitglieder: 'team/saveTeammitglieder',
    }),
    showHideDescription() {
      this.descriptionVisible = !this.descriptionVisible;
      this.descriptionTitle = this.descriptionVisible ? 'Beschreibung ausblenden' : 'Beschreibung anzeigen'
    },
    holeTeamMitarbeiter(filters) {
      return this.setTeamMitarbeiter({filters, caller: this});
    },
    selectTeamleiter(item) {
      this.clearTeammitgliederList()
      this.setSelectedTeamleiter({key: item.key});
      this.clearTeammitgliederChanges();
      this.holeTeamMitglieder();
    },
    holeTeamMitglieder() {
      let teamleiter = this.getTeamleiterKey();
      if(!teamleiter) {
        this.clearTeammitgliederList();
      }
      else {
        this.setTeamMitglieder({caller: this, leiterId: teamleiter});
      }
    },
    getTeamleiterKey() {
      for(const [key, value] of Object.entries(this.teamMitarbeiter)) {
        if(value.selected) {
          return key;
        }
      }
      return false;
    },
    changeTeamMitgliedStatus(selected, item) {
      this.setUpdated({key: item.key, selected: selected});
    },
    save() {
      let teamleiterKey = this.getTeamleiterKey();
      this.saveTeammitglieder({caller: this, teamleiterKey: teamleiterKey});
      this.selectTeamleiter({key: teamleiterKey});
    }
  },
  created() {
    this.clearTeammitgliederList();
    this.clearTeammitgliederChanges();
  }
}
</script>

<style scoped>

</style>