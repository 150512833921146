<template>
  <div class="mitarbeiter-name-filter">
    <b-form-group label="Mitarbeiter" :label-cols="11">
      <b-form-select v-model="selectedMitarbeiter"
                     :options="mitarbeiterNamen"
                     :plain="true"
                     @change="updateFilter"/>
    </b-form-group>
  </div>
</template>

<script>
import Vue from 'vue'
import { FormGroupPlugin, FormSelectPlugin } from 'bootstrap-vue'
import auftrag from '../../api/kpi-energie-api/auftrag'

Vue.use(FormGroupPlugin)
Vue.use(FormSelectPlugin)

export default {
  name: 'MitarbeiterNameFilter',
  props: {
    filterMutations: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      mitarbeiterNamen: null,
      selectedMitarbeiter: null
    }
  },
  methods: {
    updateFilter (val) {
      this.filterMutations.remove('MitarbeiterName')
      if (val !== 'Keine Auswahl') {
        this.filterMutations.add({ Name: 'MitarbeiterName', Value: val })
      }
      this.filterMutations.update()
    }
  },
  created() {
    auftrag.holeMitarbeiterNamen()
      .then(response => {
        this.mitarbeiterNamen = response.data
        this.mitarbeiterNamen.unshift('Keine Auswahl')
        this.selectedMitarbeiter = this.mitarbeiterNamen[0]
      })
  }
}
</script>

<style scoped>

</style>
