<template>
    <b-modal ref="modal" title="" hide-footer>
        <b-datepicker v-model="selectedDate"/>
        <h2 class="text-center">{{workerData.name}}</h2>
        <p v-if="hasData" class="text-center">Zeit seit letztem Auftrag</p>
        <p v-if="hasData" class="text-center">{{workerData.timeSinceLastContract}}</p>
        <b-table v-if="hasActivities" :items="activities" :fields="fields"/>
        <p v-else class="text-center">keine Daten verfügbar</p>
        <b-row>
            <b-col sm="3"/>
            <b-col sm="6">
                <b-button class="btn-block" @click="loadLastData" v-if="!hasActivities">Zum letzten Datensatz</b-button>
            </b-col>
            <b-col sm="3"/>
        </b-row>
        <p v-if="hasError" class="text-center text-danger">{{error}}</p>
    </b-modal>
</template>

<script>
import Vue from 'vue'
import VueEllipseProgress from 'vue-ellipse-progress'
import api from '../../api/kpi-energie-api/team'

  Vue.use(VueEllipseProgress)

  export default {
    name: "MitarbeiteraktivitaetModal",
    props: {
        date: {
            type: Date
        },
        workerData: {
            type: Object
        },
        closeCallback: {
            type: Function
        }
    },
    data() {
        return {
            selectedDate: null,
            fields: [
                {                    
                    label: '',
                    key: 'number'
                },
                {                    
                    label: 'Verfügbarkeit',
                    key: 'availability'
                },
                {                    
                    label: 'Übermittlung',
                    key: 'transmission'
                }
            ],
            activities:[],
            error: null
        }
    },
    computed: {
        hasData() {
            return this.workerData.timeSinceLastContract != ''
        },
        hasActivities() {
            return this.activities && this.activities.length > 0
        },
        hasError() {
            return this.error !== null
        }
    },
    watch: {
        selectedDate: {
            handler(val, oldVal) {
                this.loadData()
            }
        }
    },
    methods: {
        show(dateTime) {
            this.selectedDate = dateTime
            this.date = dateTime
            if (!this.selectedDate) {
                this.selectedDate = this.date
            }
            this.loadData()
            this.$refs['modal'].show()
        },
        loadData() {
            this.error = null
            this.activities = null
            api.holeMitarbeiterActivity([{ name: 'Name', value: this.workerData.name }], this.selectedDate).then(data => {
                    if (data.status === 200) {
                        this.workerData = data.data[0]
                    }
                    
                    api.holeMitarbeiterDetailedActivity(this.workerData.appUserId, new Date(this.selectedDate).toISOString().split('T')[0]).then(response => {
                        if (response.status == 200) {
                            this.activities = response.data
                        }
                    })
                })
        },
        loadLastData() {
            api.holeLetzteMitarbeiterActivity(this.workerData.appUserId).then(data => {
                if (data.status === 200) {
                    if (data.data.lastActivityDay && data.data.lastActivityDay !== '') {
                        this.selectedDate = data.data.lastActivityDay
                        this.loadData()
                    } else if (data.data.lastActivityDay === '') {
                        this.error = 'Mitarbeiter hat bisher noch keine Aktivitäten'
                    }
                }
            })
        }
    }
  }
</script>

<style>
</style>
