<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <navbar/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
          <ModalIdle v-if="isIdle" />
          <router-view/>
          <!--          Für debugging -->
          <!--          <p>Is it Idle? - {{ isIdle }}</p>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Navbar from './components/common/Navbar'
import {mapActions} from 'vuex'
import ModalIdle from './components/common/Modaldle'

export default {
  name: 'App',
  components: {Navbar, ModalIdle},
  methods: {
    ...mapActions({
      setToken: 'user/setToken'
    }),
  },
  data () {
    return {
      timerInterval: null,
    }
  },
  computed: {
    isIdle () {
      return (this.$route.name === 'logout') ? false : this.$store.state.idleVue.isIdle;
    }
  },
  created () {
    let token = this.$route.query.t
    if (token) {
      this.setToken(token)
      window.history.replaceState(null, document.title, window.location.href.split('?')[0])
    } else if (process.env.VUE_APP_TOKEN && process.env.NODE_ENV !== 'production') {
      this.setToken(process.env.VUE_APP_TOKEN)
    }
    if (this.$store.state.user.token === null && this.$route.name !== 'logout') {
      this.$router.push({name: 'logout'})
    }
  }
}
</script>

<style>
</style>
