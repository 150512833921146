<template>
  <b-modal ref="my-modal" hide-footer title="Achtung">
    <div class="d-block text-center">
      <h3 class="text-danger">Sitzung läuft in Kürze ab</h3>
      <p>Bitte bewegen Sie die Maus.</p>
      <p>Sie haben noch {{ time / 1000 }} Sekunden</p>
    </div>
  </b-modal>
</template>

<script>
import router from '@/router'

export default {
  name: 'ModalIdle',
  data() {
    return {
      time: 2 * 60000
    }
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    }
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000
      if (!this.$store.state.idleVue.isIdle) {
        clearInterval(timerId)
      }

      if (this.time < 116 * 1000) {
        this.showModal()
      }

      if (this.time < 1) {
        clearInterval(timerId)
        router.push({name: 'logout'})
      }
    }, 1000)
  }
}
</script>

<style scoped>
</style>