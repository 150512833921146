<template>
  <div>
    <b-navbar toggleable="md" type="light" variant="faded" class="bd-navbar">
      <b-navbar-brand href="#">
        <div class="crop">
            <img @click="gotoFcApp" src="../../assets/img/fc_logo.png" alt="Fairtriebschampions KPI Cockpit" height="70" title="Zu den FairtriebsChampions"/>
        </div>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item :to="{ name: 'dashboard'}">Dashboard</b-nav-item>
          <b-nav-item v-if="istVerwaltungErlaubt" :to="{ name: 'provisionsverwaltung' }">Verwaltung</b-nav-item>
          <b-nav-item :to="{ name: 'mitarbeiterstatistik' }">Mitarbeiter Statistiken</b-nav-item>
          <b-nav-item v-if="istKarriereverwaltungErlaubt" :to="{ name: 'karriereleiter' }">Karriereleiter</b-nav-item>
          <b-nav-item v-if="darfMitarbeiterListeEinsehen" :to="{ name: 'mitarbeiterauftragsliste' }">Mitarbeiter Auftragsliste</b-nav-item>
          <b-nav-item v-if="darfMitarbeiterListeEinsehen" :to="{ name: 'mitarbeiteraktivitaet' }">Mitarbeiteraktivität</b-nav-item>
          <b-nav-item v-if="isRestrictionListAllowed" :to="{ name: 'sperrgebieteexport' }">Adressliste</b-nav-item>
<!--          <b-nav-item href="#">Faitriebs-Fight</b-nav-item>-->
<!--          <b-nav-item href="#">Historie</b-nav-item>-->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <!-- Right aligned nav items -->
        <!--
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template v-slot:button-content>
              <em>User</em>
            </template>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item href="#">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        -->
      </b-collapse>
    </b-navbar>
    <div class="second-menu">
      &nbsp;<br>
      &nbsp;<br>
      &nbsp;
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import provision from '../../api/kpi-energie-api/provision'
import karriere from "@/api/kpi-energie-api/karriere";
import permissionApi from '../../api/kpi-energie-api/permission'

export default {
  name: 'Navbar',
  data() {
    return {
      istVerwaltungErlaubt: false,
      istKarriereverwaltungErlaubt: false,
      darfMitarbeiterListeEinsehen: false,
      isRestrictionListAllowed: false
    }
  },
  methods: {
    ...mapGetters({
      getUser: 'user/getUser'
    }),
    gotoFcApp () {
      window.location = process.env.VUE_APP_TOKEN_API_URL
    },    
    istVPPartner() {
      const user = this.getUser()
      return user !== null && user !== undefined && user.type === 'VP';
    },
  },
  created() {
    permissionApi.holePermission().then(response => {
      this.istVerwaltungErlaubt = response.darfProvisionVerwalten
      this.istKarriereverwaltungErlaubt = response.darfKarriereLeiterVerwalten
      this.darfMitarbeiterListeEinsehen = response.darfMitarbeiterListeEinsehen
      this.isRestrictionListAllowed = response.darfSperrgebieteListeEinsehen
    })
  }
}
</script>

<style scoped>
.crop {
  width: 200px;
  height: 70px;
  overflow: hidden;
}

.crop img {
  height: 100%;
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.bd-navbar {
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, .25), inset 0 -1px 5px rgba(0, 0, 0, .25);
}
.navbar-light .navbar-nav .nav-link {
  color: #1d1dc4;
}
.second-menu {
  background: rgb(59,164,242);
  background: linear-gradient(90deg, rgba(59,164,242,1) 0%, rgba(14,0,98,1) 100%);
}
</style>
