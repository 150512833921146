<template>
  <div>
    <div class="text-center sticky py-3">
      <b-button class="mr-3"
                :variant="changedItems.length > 0 ? 'secondary' : 'info'"
                size="lg"
                v-b-modal.save
                :disabled="saveButtonDisabled"
                >Änderungen speichern</b-button>
      <b-modal id="save" size="lg" title="Änderungen speichern" cancel-title="Abbrechen" @ok="save">
        <p class="my-4">
          Sicher, dass diese Änderungen gespeichert werden sollen?
          <b-table responsive :items="changedItems" :fields="modalFields">
          </b-table>
        </p>
      </b-modal>
      <b-button class="mr-3"
                :variant="changedItems.length > 0 ? 'secondary' : 'info'"
                size="lg"
                v-b-modal.reset
                :disabled="resetButtonDisabled">Änderungen zurücksetzen</b-button>
      <b-modal id="reset" size="lg" title="Änderungen zurücksetzen" cancel-title="Abbrechen" @ok="reset">
        <b-alert variant="warning" show>Willst du wirklich alle nicht gespeicherten Änderungen löschen?</b-alert>
      </b-modal>
    </div>
    <b-button @click="showHideDescription">{{ descriptionTitle }}</b-button>
    <div v-if="descriptionVisible">
      <p>Hier kannst du die Karrierestufen deiner Mitarbeiter verwalten</p>
    </div>
    <common-table :fields="fields"
                  :busy="viewstate.loading"
                  :sortBy="sortBy"
                  :usePagination="false"
                  :useClientSorting="true"
                  :data-provider="holeKarrieren"
                  :use-sticky="false"
                  show-empty>
      <template v-slot:empty>
        <h4 class="py-3 text-center">
          Keine Mitarbeiter gefunden.
        </h4>
      </template>
      <template #cell(created)="row">
        {{ row.item.created | formatDate }}
      </template>
      <template #cell(level)="row">
        <b-form-select :key="row.item.key"
                       :plain="true"
                       :options="karrierestufen"
                       :id="row.item.key"
                       :value="(getKarriereByKey(row.item.key) ? getKarriereByKey(row.item.key) : row.item.level)"
                       size="sm"
                       @change="changeKarriere($event, row.item)"
        />
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </common-table>
  </div>
</template>

<script>
import CommonTableMixin from '@pso/vue-shared/mixins/componentRelation/commonTable'
import karriere from "@/api/kpi-energie-api/karriere";
import {mapActions, mapState, mapGetters} from "vuex"
import {
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
} from "../../store/modules/viewstate"

export default {
  name: "KarriereleiterTabelle",
  mixins: [CommonTableMixin],
  computed: {
    ...mapState({
      viewstate: state => state.viewstate.viewstate,
      karriereChanges: state => state.karriere.karriereChanges,
      karriere: state => state.karriere.karrieren,
      filters: state => state.karriere.filters
    }),
    ...mapGetters({
      changedKarriereByKey: 'karriere/changedKarriereByKey'
    }),
    changedItems() {
      return Object.keys(this.karriereChanges)
      .filter(c => this.karriere[c] && this.karriereChanges[c])
      .map(c => {
        return {
          key: c,
          name: this.karriere[c].name,
          oldLevel: this.karriere[c].level,
          level: this.karriereChanges[c],
        }
      })
    },
    saveButtonDisabled() {
      return this.changedItems.length === 0
    },
    resetButtonDisabled() {
      return this.changedItems.length === 0
    },
  },
  data() {
    return {
      fields: [
        {key: 'name', label: 'Name', sortable: true},
        {key: 'key', label: 'Partnernummer', sortable: true},
        {key: 'created', label: 'Startdatum', sortable: true},
        {key: 'level', label: 'Stufe Karriereleiter', sortable: true},
        {key: 'status', label: 'Status', sortable: true},
      ],
      modalFields : [
        {key: 'key', label: 'Partnernummer'},
        {key: 'name', label: 'Name'},
        {key: 'oldLevel', label: 'Alt'},
        {key: 'level', label: 'Neu'},
      ],
      sortBy: 'name',
      karrierestufen: null,
      descriptionVisible: false,
      descriptionTitle: 'Beschreibung anzeigen',
    }
  },
  methods: {
    ...mapActions({
      setState: "viewstate/setState",
      setKarriereList: 'karriere/setKarriereList',
      setUpdated: 'karriere/setUpdated',
      saveChanges: 'karriere/saveChanges',
      resetChanges: 'karriere/resetChanges',
      setLevel: 'karriere/setLevel',
      setFilters: 'karriere/setFilters',
    }),
    save() {
      this.saveChanges(this);
    },
    reset() {
      this.resetChanges(this);
    },
    showHideDescription () {
      this.descriptionVisible = !this.descriptionVisible
      this.descriptionTitle = this.descriptionVisible ? 'Beschreibung ausblenden' : 'Beschreibung anzeigen'
    },
    getKarriereByKey(key) {
      return this.changedKarriereByKey(key) ?? null
    },
    changeKarriere(level, item) {
      this.setUpdated({key: item.key, level: level});
    },
    holeKarrieren(filters) {
      return this.setKarriereList({filters, caller: this});
    }
  },
  created() {
    karriere.holeKarrierestufen()
    .then((response) => {
      let data = response.data;
      this.karrierestufen = [];
      data.forEach(item => {
        this.karrierestufen.push(item.name);
      })
    })
  }
}
</script>

<style scoped>

</style>